import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { respondTo } from "../_respondTo"
import { palette } from "../_variables"

const Button = styled(Link)`
  background-color: ${palette.primary};
  color: ${palette.primaryContrast};
  border-radius: 0.5em;
  padding: 0.25em 0.5em;
  text-decoration: none;

  &:hover {
    background-color: ${palette.secondary};
    color: ${palette.primaryContrastStong};
  }

  &.alt {
    background-color: ${palette.secondary};

    &:hover {
      background-color: ${palette.secondaryLight};
    }
  }
  ${respondTo.md`


  `}
`

function TwmButton({ toRef, children, type }) {
  return (
    <Button to={toRef} className={type}>
      {children}
    </Button>
  )
}

export default TwmButton
